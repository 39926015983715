<template>
  <div class="home">
    <background-paper>
      <form-button @click="goToPlay">
        Play! v5
      </form-button>
    </background-paper>
  </div>
</template>

<script>
import BackgroundPaper from '../components/BackgroundPaper'
import FormButton from '../components/form/FormButton'

export default {
  name: 'HomePage',

  components: {
    BackgroundPaper,
    FormButton
  },

  methods: {
    goToPlay () {
      this.$router.push({ name: 'PlayPage' })
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}
</style>
