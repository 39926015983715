export default {
  game: {
    numberOfRows: 0,
    numberOfColumns: 0,
    cellGrid: {},
    sideGrid: {},
    activePlayer: 0,
    clicking: null,
    round: 0,
    type: 'single-player',
    status: null,
    players: []
  }
}
