<template>
  <div class="paper">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BackgroundPaper'
}
</script>

<style lang="scss" scoped>
.paper {
  background: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  padding: 24px;
  position: relative;
  width: 80%;
  min-height: 90vh;

  &::before, &::after {
    content: "";
    height: 98%;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  &::before {
    background: #fafafa;
    box-shadow: 0 0 8px rgba(0,0,0,0.2);
    left: -5px;
    top: 4px;
    transform: rotate(-2.5deg);
  }

  &::after {
    background: #f6f6f6;
    box-shadow: 0 0 3px rgba(0,0,0,0.2);
    right: -3px;
    top: 1px;
    transform: rotate(1.4deg);
  }
}
</style>
