export default {
  setNewGame (state, newGame) {
    const GAME_GRIDS = createGameGrids(newGame.rows, newGame.columns)

    state.game.cellGrid = GAME_GRIDS.cellGrid
    state.game.sideGrid = { ...GAME_GRIDS.lineGrid, ...GAME_GRIDS.barGrid }
    state.game.numberOfRows = newGame.rows
    state.game.numberOfColumns = newGame.columns

    state.game.players = newGame.players.map((a) => [Math.random(), a]).sort((a, b) => a[0] - b[0]).map((a) => a[1])

    state.game.activePlayer = 0
    state.game.clicking = null
    state.game.round = 1
    state.game.status = 0
  },

  setSideGridClicked (state, sideId) {
    var side = state.game.sideGrid[sideId]
    if (side.clickedBy === null) {
      side.clickedBy = state.game.activePlayer
    }
  },

  cellChanged (state, changes) {
    var cell = state.game.cellGrid[changes.cell]
    if (cell && !cell[changes.side]) {
      cell[changes.side] = true
      if (cell.top && cell.bottom && cell.left && cell.right) {
        cell.ownedBy = state.game.activePlayer
        cell.ownedOnRound = state.game.round
        var status = checkGameStatus(state.game.cellGrid)
        if (status.endGame) {
          alert('End Game')
        }
      }
    }
  },

  setClicking (state) {
    state.game.clicking = state.game.activePlayer
  },

  unsetClicking (state) {
    state.game.clicking = null
  },

  setNextPlayer (state) {
    var nextPlayer = (state.game.activePlayer === state.game.players.length - 1) ? 0 : state.game.activePlayer + 1
    state.game.activePlayer = nextPlayer
    state.game.round += 1
  }
}

export const getCellName = (row, column) => {
  return `c_${row}_${column}`
}

export const getLineName = (row, column) => {
  return `l_${row}_${column}`
}

export const getBarName = (row, column) => {
  return `b_${row}_${column}`
}

function createGameGrids (numberOfRows, numberOfColumns) {
  var cellGrid = {}
  var lineGrid = {}
  var barGrid = {}

  for (var rowCount = 0; rowCount < numberOfRows; rowCount++) {
    for (var columnCount = 0; columnCount < numberOfColumns; columnCount++) {
      cellGrid[getCellName(rowCount, columnCount)] = createCellObject(rowCount, columnCount)
      lineGrid[getLineName(rowCount, columnCount)] = createLineObject(rowCount, columnCount)
      barGrid[getBarName(rowCount, columnCount)] = createBarObject(rowCount, columnCount)
    }
    // on more bar to close de row
    barGrid[getBarName(rowCount, columnCount)] = createBarObject(rowCount, columnCount)
  }
  // on more line to close de grid
  for (columnCount = 0; columnCount < numberOfColumns; columnCount++) {
    lineGrid[getLineName(numberOfRows, columnCount)] = createLineObject(numberOfRows, columnCount)
  }

  return { cellGrid, lineGrid, barGrid }
}

function createCellObject (row, column) {
  return {
    id: getCellName(row, column),
    ownedBy: null,
    ownedOnRound: null,
    row: row,
    column: column,
    top: false,
    bottom: false,
    left: false,
    right: false
  }
}

function createLineObject (row, column) {
  return {
    id: getLineName(row, column),
    type: 'line',
    clickedBy: null,
    row: row,
    column: column,
    cell1: getCellName(row, column),
    cell1Type: 'top',
    cell2: getCellName(row - 1, column),
    cell2Type: 'bottom'
  }
}

function createBarObject (row, column) {
  return {
    id: getBarName(row, column),
    type: 'bar',
    clickedBy: null,
    row: row,
    column: column,
    cell1: getCellName(row, column),
    cell1Type: 'left',
    cell2: getCellName(row, column - 1),
    cell2Type: 'right'
  }
}

function checkGameStatus (cellGrid) {
  var players = {}
  var endGame = true

  for (var cellName in cellGrid) {
    var cell = cellGrid[cellName]
    if (cell.ownedBy !== null) {
      if (!(cell.ownedBy in players)) {
        players[cell.ownedBy] = 0
      }
      players[cell.ownedBy] += 1
    } else {
      endGame = false
    }
  }

  return { endGame, players }
}
