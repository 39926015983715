export default {
  createGame ({ commit, dispatch }, newGame) {
    commit('setNewGame', newGame)
    dispatch('checkAiMove')
  },

  setSideClicked ({ commit, dispatch, state }, sideId) {
    var side = state.game.sideGrid[sideId]

    if (state.game.clicking === null && side.clickedBy === null) {
      commit('setClicking')
      commit('setSideGridClicked', side.id)
      commit('cellChanged', { cell: side.cell1, side: side.cell1Type })
      commit('cellChanged', { cell: side.cell2, side: side.cell2Type })
      var cell1 = state.game.cellGrid[side.cell1]
      var cell2 = state.game.cellGrid[side.cell2]

      if ((!cell1 || cell1.ownedBy === null) && (!cell2 || cell2.ownedBy === null)) {
        commit('setNextPlayer')
      }

      setTimeout(() => {
        commit('unsetClicking')
        dispatch('checkAiMove')
      }, 300)
    }
  },

  checkAiMove ({ dispatch, state }) {
    var activePlayer = state.game.players[state.game.activePlayer]
    if (state.game.type === 'single-player' && activePlayer.type === 'ai') {
      dispatch('aiMove', activePlayer.aiLevel)
    }
  },

  aiMove ({ dispatch, state }, aiLevel) {
    const AI_LEVEL = aiLevel
    const CELLS_GRID = state.game.cellGrid
    const SIDES_GRID = state.game.sideGrid
    var sidesAvailable = []
    var sidesDesirable = []
    var sidesUndesirable = []

    for (var i in SIDES_GRID) {
      var side = SIDES_GRID[i]
      if (side.clickedBy === null) {
        sidesAvailable.push(side)
        const CELL1 = CELLS_GRID[side.cell1]
        const CELL2 = CELLS_GRID[side.cell2]
        if (
          (CELL1 && (CELL1.top + CELL1.bottom + CELL1.left + CELL1.right) === 3) ||
          (CELL2 && (CELL2.top + CELL2.bottom + CELL2.left + CELL2.right) === 3)
        ) {
          sidesDesirable.push(side)
        }
        if (
          (CELL1 && (CELL1.top + CELL1.bottom + CELL1.left + CELL1.right) === 2) ||
          (CELL2 && (CELL2.top + CELL2.bottom + CELL2.left + CELL2.right) === 2)
        ) {
          sidesUndesirable.push(side)
        }
      }
    }

    var sidesFiltered = sidesAvailable.filter(x => !sidesUndesirable.includes(x))

    var choosedSide = null
    if (AI_LEVEL >= 2 && sidesDesirable.length > 0) {
      choosedSide = sidesDesirable[Math.floor(Math.random() * sidesDesirable.length)]
    } else if (AI_LEVEL >= 3 && sidesFiltered.length > 0) {
      choosedSide = sidesFiltered[Math.floor(Math.random() * sidesFiltered.length)]
    } else if (sidesAvailable.length > 0) {
      choosedSide = sidesAvailable[Math.floor(Math.random() * sidesAvailable.length)]
    }

    if (choosedSide !== null) {
      dispatch('setSideClicked', choosedSide.id)
    }
  }
}
